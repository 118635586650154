<template>
  <div style="background: #fff; height: 100%;">
    <van-nav-bar title="上传付款二维码" left-arrow :fixed="true" :z-index="999" @click-left="back">
    </van-nav-bar>
    <div class="pt">
      <div class="fkname" style="margin-bottom: 40px;"><span class="fg">提示:</span><span>上传得二维码会在所有的账单链接中展示</span></div>


      <div class="scsdiv" v-if="url" style="position: relative;">
        <img class="scimg" :src="url" alt="">
        <img class="guanbi" @click.stop="setaddpic('')" src="../../../assets/img/guan2.png" alt="">
      </div>
      <div v-else style="display: flex;align-items: center;justify-content: center;">
        <van-uploader :max-count="1" :after-read="onReadfkm" :name="2">
          <div class="scdiv">
            <van-icon size="60" color="#cbcbcb" name="plus" />
          </div>

        </van-uploader>
      </div>
      <div style="text-align: center;margin-top: 20px;">{{ shangjia.name }} 收款二维码</div>
    </div>
    <div class="heng"></div>
  </div>
</template>
  
<script>
import { sourcecode, uploadPicture, getsourceamount,getGarageInfo  } from "@/api/check";
export default {
  data() {
    return {
      form: {}, //
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")) || {},
      gid: this.$route.query.gid,
      url: null,
      key: "",
      page: 0,
      size: 20,
      loading: false,
      total: 0,
      finished: false,
      isiOS: false,
    };
  },
  methods: {

    async onReadfkm(file, detail) {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "上传中...",
      });
      // alert(sessionStorage.getItem('h5token'))
      var _this = this;
      console.log(file);
      console.log(detail);
      // return
      // console.log(file.file);
      let files = this.dataURLtoFile(file.content, file.file.name);
      let formdata1 = new FormData();
      formdata1.append("file", files);
      // alert(sessionStorage.getItem('h5token'))

      uploadPicture(formdata1).then((e) => {
        loading.clear();
        if (e.code == 200) {
          console.log(e);
          // this.$toast.success("上传图片成功");
          this.url = e.data;
          _this.setaddpic(e.data);
        }
      });
    },
    dataURLtoFile(dataurl, filename) {
      // 将base64转换为file文件
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime,
      });
    },
    setaddpic(url) {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      var data = {
        gid: this.gid,
        url: url,
      };
      sourcecode(data).then((e) => {
        loading.clear();
        if (e.code == 200) {

          if(url == ''){
            this.url = null;
            this.$toast.success("删除成功");
          }else{
            this.$toast.success("上传成功");
          }
        }
      });
    },
    getlist() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      getsourceamount({ gid: this.gid }).then((e) => {
        loading.clear();
        if (e.code == 200) {
          console.log(e)
          this.url = e.data
        }
      });
    },
    getshangjia() {
      getGarageInfo({
        gid: this.gid,
      })
        .then((e) => {
          // loading.clear();
          this.shangjia = e.data;
          sessionStorage.setItem("appid", e.data.idd);
          sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
          this.getlist();
        })
        .catch(() => loading.clear());
    },
    back() {
      this.$router.go(-1);
    },
  },
  created() {

    this.getlist();
    this.getshangjia()
    //判断是否在微信环境

  },
  mounted() { },
  computed: {},
};
</script>
<style></style>
<style lang="less" scoped>
.pt {
  padding-top: 46px;

  .fg {
    font-size: 14px;
    color: #000;

  }

  .scimg {
    width: 280px;
    height: 280px;
    text-align: center;

    margin: auto;
  }

  .scsdiv {
    width: 280px;
    height: 280px;
    position: relative;
    margin: auto;

    .guanbi {
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
    }
  }

  .scdiv {
    width: 280px;
    height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #cbcbcb;
    position: relative;




  }

  .van-uploader {
    margin-top: 20px;
  }

  .fkname {
    text-align: center;
    font-size: 14px;
    padding-top: 30px;
    color: red;
    font-weight: bold;
  }

  .jin {
    display: block;
    margin: auto;
    width: 40px;
    margin-top: 30px;
  }

  .jiner {
    text-align: center;
    font-size: 28px;
    padding-top: 10px;
  }
}

.heng {
  height: 10px;
  width: 100%;
  background: #f6f6f6;
  margin-top: 40px;
}
</style>

  
   